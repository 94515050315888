import "./firebase";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import {
  captureConsoleIntegration,
  replayIntegration,
  feedbackIntegration,
  replayCanvasIntegration
} from "@sentry/browser";
import AppWrapper from "./AppWrapper.jsx";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/store";
import { Provider } from "react-redux";
import { firebaseApp, projectId } from "./firebase";
import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "./firebase";
import { shouldLogToErrorMonitor } from "./utils/errorHandlers";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import "./global"; // This should be the first import
import {
  createBrowserRouter,
  createRoutesFromElements,
  createRoutesFromChildren,
  Route,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router";

import { RouterProvider } from "react-router/dom";
import App from "./App.jsx";
import { UserApp } from "./UserApp.jsx";
import Layout from "./Layout.jsx";
import Router from "./Router.jsx";

const isProduction =
  projectId === "pangea-291110" || projectId === "alethea-dda54";
const isDev = projectId === "alethea-dev";

if (typeof window !== "undefined" && process.env.NODE_ENV !== "production") {
  // const axe = require('@axe-core/react');
  // axe(React, ReactDOM, 1000);
}

if (shouldLogToErrorMonitor()) {
  console.log("Initing sentry");
  Sentry.init({
    dsn: "https://b5eda7ef2d694c6099e41a8689b07d45@o1281458.ingest.sentry.io/6487416",

    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      captureConsoleIntegration(),
      replayIntegration({
        blockAllMedia: false,
        maskAllText: false,
        mask: [".sentry-mask"]
      }),
      feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: "dark",
        autoInject: false
      }),
      replayCanvasIntegration()
    ],
    // debug: isProduction,
    environment: isProduction
      ? "production"
      : isDev
        ? "development"
        : "staging",
    release: process.env.REACT_APP_RELEASE_VERSION,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // We track whole of prod and stage
    replaysSessionSampleRate: 1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0
  });
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  profileFactory: (userData, profileData, firebase) => {
    const { user } = userData;
    return {
      foo: "bazz"
    };
  }
};

const rrfProps = {
  firebase: firebaseApp, // firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // needed if using firestore
};

const container = document.getElementById("root");
const root = createRoot(container);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/*" element={<AppWrapper />}>
      <Route path="*" element={<App />}>
        <Route path="*" element={<UserApp />}>
          <Route path="*" element={<Layout />}>
            <Route element={<Router />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig.options}>
        <RouterProvider router={router} />
      </FirebaseAppProvider>
    </ReactReduxFirebaseProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
