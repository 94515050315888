/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useFeatureFlags } from "./hooks/useFeatureFlags";
import { FEATURE_FLAGS, PRODUCTION_PROJECT_IDS } from "./consts";
import { useSelector } from "react-redux";
import { projectId } from "./firebase";

export const UserApp = () => {
  // Hooks
  const userId = useSelector((state) => state.firebase.auth.uid);
  const userProfile = useSelector((state) => state.user.userProfile);
  const isPendoEnabled = useFeatureFlags(FEATURE_FLAGS.ENABLE_PENDO);

  // Ephemeral state
  const [pendoInitialized, setPendoInitialized] = useState(false);

  // Constants
  const isLocalhost = window.location.hostname === "localhost";
  const isProductionEnv = PRODUCTION_PROJECT_IDS.includes(projectId)

  useEffect(() => {
    if (
      !isLocalhost &&
      isPendoEnabled &&
      !pendoInitialized &&
      userId &&
      userProfile.selectedCourse
    ) {
      if (typeof Oti !== "undefined" && typeof Oti.OtiService !== "undefined") {
        const otiService = new Oti.OtiService();
        otiService.initialize({
          ot_key: "0193ba53-ca55-7a0a-bb63-3dc03a0caf59",
          prod_env: isProductionEnv,
          pendo_key: "27782fb6-7abe-48ac-7547-ae6aba153327",
          pendo_details: {
            visitor: { id: userId },
            account: { id: userProfile.selectedCourse.institution_id }
          },
          pendo_visitor_url:
            "https://app.eu.pendo.io/api/v1/metadata/visitor/agent/value?create=true",
          pendo_dnp_url: `https://app.eu.pendo.io/api/v1/metadata/visitor/pendo/value/${userId}/donotprocess`,
          pendo_metadata_url: `https://app.eu.pendo.io/api/v1/metadata/visitor/custom/value/${userId}/APPID_targetingcookies`,
          request_headers: {
            "x-pendo-integration-key":
              "6f9eeb33-3b9f-4b9c-5938-8525d3e410bb.eu",
            "Content-Type": "application/json"
          },
          pendo_optimize_requests: false,
          zendesk_key: "94d6e12d-accd-409d-a5b6-4b6354a43e0b",
          zendesk_appName: "clarivate8549"
        });
        setPendoInitialized(true);
      }
    }
  }, [userId, userProfile, pendoInitialized, isPendoEnabled, isLocalhost]);

  return <Layout />;
};
