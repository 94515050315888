import React, { useMemo } from "react";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CheckIcon from "@mui/icons-material/Check";
import { generateBackgroundColor } from "../../../utils/avatar-utils.js";
import { generateAccessibleTextColor } from "../../../utils/avatar-utils.js";
import { getInitials } from "../../../utils/avatar-utils.js";
import { useSearchParams } from "react-router";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "flex-start",
    alignItems: "center"
  },
  btn: {
    color: "white",
    "&.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.7)"
    }
  }
}));

const StyledSelect = styled(Select)`
  &:before {
    border-bottom: none;
  }
  &:after {
    border-bottom: none;
  }
  &:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  & .MuiSelect-standard {
    color: white;
    margin-right: 24px;
  }
  & .MuiSelect-icon {
    color: white;
  }
`;

export default function FeedbackSwitcher({ task, currentSubmission }) {
  const classes = useStyles();
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();

  const submissions = useMemo(
    () =>
      task.submissions
        ?.filter((s) => s.status === "Submitted" || s.status === "Graded")
        .sort((a, b) => a.user_name.localeCompare(b.user_name)) ?? [],
    [task.submissions]
  );

  const isFirst = currentSubmission.id === submissions[0]?.id;
  const isLast =
    currentSubmission.id === submissions[submissions.length - 1]?.id;

  const handleChange = (submissionId) => {
    searchParams.set("submission_id", submissionId);
    setSearchParams(searchParams);
  };

  const backgroundColor = generateBackgroundColor(currentSubmission.owner);
  const textColor = generateAccessibleTextColor(backgroundColor);

  return (
    <Box className={clsx("excludeParent", classes.container)}>
      <IconButton
        variant="text"
        size="small"
        className={classes.btn}
        disabled={isFirst}
        onClick={() => {
          const index = submissions.findIndex(
            (s) => s.id === currentSubmission.id
          );
          handleChange(submissions[index - 1].id);
        }}>
        <ChevronLeftIcon />
      </IconButton>
      <StyledSelect
        variant="standard"
        value={currentSubmission.id}
        renderValue={() => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              className={classes.avatar}
              alt={currentSubmission.user_name}
              sx={{
                backgroundColor: backgroundColor,
                color: textColor,
                width: "20px",
                height: "20px",
                marginRight: "8px",
                fontSize: "12px"
              }}>
              {getInitials(currentSubmission.user_name)}
            </Avatar>
            <Typography variant="body1">
              {currentSubmission.user_name}
            </Typography>
          </Box>
        )}
        onChange={(event) => handleChange(event.target.value)}>
        <Box
          value=""
          sx={{
            marginBottom: "8px",
            marginInline: "8px",
            cursor: "default",
            "&:hover": { backgroundColor: "transparent" }
          }}>
          <Alert severity="info">
            {intl.formatMessage({
              defaultMessage: "Showing submitted only",
              id: "feedback.showingSubmittedOnly"
            })}
          </Alert>
        </Box>
        {submissions.map((submission) => (
          <MenuItem
            key={submission.id}
            value={submission.id}
            sx={{
              minWidth: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <Box>{submission.user_name}</Box>
            {submission.status === "Graded" && <CheckIcon />}
          </MenuItem>
        ))}
      </StyledSelect>
      <IconButton
        variant="text"
        size="small"
        className={classes.btn}
        disabled={isLast}
        onClick={() => {
          const index = submissions.findIndex(
            (s) => s.id === currentSubmission.id
          );
          handleChange(submissions[index + 1].id);
        }}>
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
}
