// Dependencies
import React, { useRef, useState, useEffect, useCallback } from "react";
import { Link, useMatch, useNavigate, useLocation } from "react-router";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { collection, onSnapshot } from "firebase/firestore";
import { useFirestore } from "reactfire";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { resetNotificationBedge } from "./redux/firebaseMiddleware";
import { selectIsImpersonation } from "./redux/userSlice";
import { toggleDarkMode } from "./redux/userSlice";
import {
  selectTextDirection,
  selectAvatar,
  selectDarkMode
} from "./redux/firestoreSelectors";

// Components
import "./App.css";
import NotificationsMenu from "./components/menus/NotificationsMenu";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Notifications from "@mui/icons-material/Notifications";
import HeaderButtonConstructor from "./components/SharedComponents/HeadeButtonConstructor";
import HelpOutline from "@mui/icons-material/HelpOutline";
import {
  ClickAwayListener,
  Typography,
  Box,
  Badge,
  AppBar,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem
} from "@mui/material";
import { CustomAvatar } from "./components/SharedComponents";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { useFeatureFlags } from "./hooks/useFeatureFlags";
import { FEATURE_FLAGS } from "./consts";
import Brightness2Icon from "@mui/icons-material/Brightness2";
import Brightness2OutlinedIcon from "@mui/icons-material/Brightness2Outlined";

// Styles
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 5,
    top: 5,
    color: theme.palette.secondary.contrast,
    fontSize: "12px"
  }
}))(Badge);

const useStyles = makeStyles((theme) => ({
  appBar: {
    textAlign: "start",
    flexFlow: "row nowrap",
    alignItems: "center",
    height: "64px",
    paddingInline: theme.spacing(3),
    backgroundColor: "#252525",
    backgroundImage: "none",
    boxShadow: "none",
    zIndex: 150
  },
  avatarMenuLtr: {
    justifyContent: "right",
    direction: "ltr",
    textAlign: "left"
  },
  menu: {
    display: "inline",
    whiteSpace: "nowrap",
    fontSize: "16px",
    fontWeight: "300",
    color: "#e0e0e0",
    textDecoration: "none",
    "&:hover": {
      color: "#FFFFFF"
    },
    fontFamily: theme.typography.fontFamily
  },
  avatarMenuItem: {
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "0.1px",
    lineHeight: "22px",
    "&:hover": {}
  },
  menuActive: {
    color: "#FFFFFF"
  },
  title: {
    marginInlineEnd: theme.spacing(5),
    fontSize: "24px",
    color: "white",
    textDecoration: "none",
    // To visualy align with navigation
    marginBottom: theme.spacing(0.5),
    fontFamily: theme.typography.fontFamily
  },
  avatarFallback: {
    width: "100%",
    height: "100%"
  },
  notificationsIcon: {
    color: "currentColor"
  },
  helpIcon: {
    color: "currentColor",
    marginLeft: "auto"
  },
  darkModeIcon: {
    color: "currentColor",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    },
    transform: "rotate(120deg)"
  },
  navigationContainer: {
    flex: "1 1 auto",
    display: "flex",
    "&  a": { textDecoration: "none" }
  },
  appNavigation: {
    display: "flex",
    "& > li": {
      padding: 0,
      marginInlineEnd: theme.spacing(6)
    }
  },
  userNavigation: {
    flex: "none",
    display: "flex",
    justifyContent: "space-between",
    "& > li": {
      padding: 0
      //marginInlineStart: theme.spacing(2)
    }
  },
  skipLink: {
    position: "absolute",
    top: -40,
    left: 0,
    padding: "4px",
    zIndex: 100,
    opacity: 0,
    "&:focus": {
      opacity: 1,
      top: 0,
      outline: "2px solid white"
    }
  }
}));

export default function MyAppBar() {
  // Hooks
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const avatarElRef = useRef();
  const notificationIconsRef = useRef();
  const dispatch = useDispatch();
  const firestore = useFirestore();

  const isReader = !!useMatch({ path: "/reader" });
  const isTasks = !!useMatch({ path: "/tasks" });
  const isLibrary = !!useMatch({ path: "/library" });
  const isContact = !!useMatch({ path: "/contactUs" });

  // Redux state
  const currentUser = useSelector((state) => state.user.auth);
  const selectedTextId = useSelector((state) => state.texts.selectedTextId);
  const textDirection = useSelector((state) => selectTextDirection(state));
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile.selectedCourseId
  );
  const avatar = useSelector((state) => selectAvatar(state));
  const isImpersonation = useSelector((state) => selectIsImpersonation(state));
  const isHelpCenterEnabled = useFeatureFlags(FEATURE_FLAGS.HELP_CENTER);
  const darkMode = useSelector((state) => selectDarkMode(state));

  // Ephemeral state
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notificationsBadgeCount, setNotificationsBadgeCount] = useState(0);
  const [firebaseNotifications, setFirebaseNotifications] = useState([]);
  const notificationsRef = collection(
    useFirestore(),
    "notifications",
    `${currentUser.uid}/userNotifications`
  );
  // Variables
  const reader = intl.formatMessage({
    id: "appBar.reader",
    defaultMessage: "Reader"
  });
  const tasks = intl.formatMessage({
    id: "appBar.tasks",
    defaultMessage: "Assignments"
  });
  const library = intl.formatMessage({
    id: "appBar.library",
    defaultMessage: "Library"
  });
  const contactUs = intl.formatMessage({
    id: "appBar.contactUs",
    defaultMessage: "Contact us"
  });

  const fetchData = useCallback(() => {
    const unsubscribe = onSnapshot(notificationsRef, (querySnapshot) => {
      let notifications = [];
      querySnapshot.forEach((doc) => {
        notifications.push({ id: doc.id, ...doc.data() });
      });
      setFirebaseNotifications(notifications);
    });

    return unsubscribe; // This function will be called when the component unmounts
  }, [notificationsRef]);

  useEffect(() => {
    const unsubscribe = fetchData();

    return () => {
      // Unsubscribe when the component unmounts
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (firebaseNotifications) {
      const unreadNotifications = firebaseNotifications.reduce(
        (accumulator, notification) =>
          notification.includeInCounter ? accumulator + 1 : accumulator,
        0
      );
      setNotificationsBadgeCount(unreadNotifications);
    }
  }, [firebaseNotifications]);

  const renderHelpIcon = () => {
    return isHelpCenterEnabled ? (
      <ListItem>
        <IconButton
          aria-label="help"
          color="primary"
          className={classes.helpIcon}
          onClick={() => {
            window?.zE?.("webWidget", "show");
            window?.zE?.("webWidget", "open");
          }}
          size="large">
          <HelpOutline style={{ fontSize: 32 }} />
        </IconButton>
      </ListItem>
    ) : null;
  };
  const renderMenu = () => {
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <Menu
          anchorEl={avatarElRef.current}
          keepMounted
          classes={{ list: classes.avatarMenuLtr }}
          open={Boolean(menuOpen)}
          onClose={() => setMenuOpen(false)}
          disableScrollLock={true}>
          <MenuItem
            className={clsx(classes.avatarMenuItem, classes.left)}
            onClick={() => {
              setMenuOpen(false);
              navigate("/profile");
            }}>
            <FormattedMessage id="view.profile" defaultMessage="View Profile" />
          </MenuItem>

          {(currentUser.isAdmin || currentUser.isDataViewer) && (
            <MenuItem
              className={clsx(classes.avatarMenuItem, classes.left)}
              onClick={() => {
                navigate("/admin");
                setMenuOpen(false);
              }}>
              <FormattedMessage
                id="view.profile"
                defaultMessage="System Console"
              />
            </MenuItem>
          )}

          <MenuItem
            className={clsx(classes.avatarMenuItem, classes.left)}
            onClick={() => {
              setMenuOpen(false);
              navigate("/logout");
            }}>
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </MenuItem>
        </Menu>
      </ClickAwayListener>
    );
  };

  function toggleNotifications() {
    setIsNotificationsOpen((prevOpen) => !prevOpen);
    dispatch(resetNotificationBedge());
  }

  const handleClose = (event) => {
    if (
      notificationIconsRef.current &&
      notificationIconsRef.current.contains(event.target)
    ) {
      return;
    }

    setIsNotificationsOpen(false);
  };

  // Render
  return (
    <>
      <AppBar
        component="div"
        dir={textDirection}
        className={clsx(classes.appBar, classes[textDirection])}
        position="static"
        name="app-bar">
        <Typography component="h1" sx={{ display: "inline" }}>
          <Link to="/library" className={classes.title}>
            Alethea
          </Link>
        </Typography>
        <Box
          aria-label="Alethea App"
          component="nav"
          className={classes.navigationContainer}>
          <List className={classes.appNavigation}>
            <ListItem>
              <Link
                title={tasks}
                data-test-id="appbar-tasks-btn"
                to={
                  selectedCourseId
                    ? `/tasks?course_id=${selectedCourseId}`
                    : "/tasks"
                }
                aria-current={isTasks && "page"}>
                <Typography
                  variant="h6"
                  className={clsx(classes.menu, isTasks && classes.menuActive)}>
                  {tasks}
                </Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link
                title={library}
                data-test-id="appbar-library-btn"
                to={
                  selectedCourseId
                    ? `/library?course_id=${selectedCourseId}`
                    : `/library`
                }
                aria-current={isLibrary && "page"}>
                <Typography
                  variant="h6"
                  className={clsx(
                    classes.menu,
                    isLibrary && classes.menuActive
                  )}>
                  {library}
                </Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link
                title={reader}
                to={
                  selectedTextId
                    ? `/reader?text_id=${selectedTextId}`
                    : "/library"
                }
                className={clsx(
                  classes.menu,
                  location.pathname === "/" && classes.menuActive,
                  isReader && classes.menuActive
                )}
                aria-current={isReader && "page"}>
                {reader}
              </Link>
            </ListItem>
            <ListItem>
              <Link
                title={contactUs}
                to="/contactUs"
                aria-current={isContact && "page"}>
                <Typography
                  variant="h6"
                  className={clsx(
                    classes.menu,
                    isContact && classes.menuActive
                  )}>
                  {contactUs}
                </Typography>
              </Link>
            </ListItem>
            <ListItem>
              <a
                href="#scroll-box"
                className={clsx(classes.menu, classes.skipLink)}>
                Skip to main content
              </a>
            </ListItem>
          </List>
        </Box>

        <List disablePadding className={classes.userNavigation}>
          {renderHelpIcon()}

          <Box component="span">
            <HeaderButtonConstructor
              intlStringId="menu.tooltip.darkMode"
              defaultMessage="Dark mode"
              placement="bottom"
              iconLabel="Dark mode"
              className={clsx(classes.darkModeIcon)}
              handleClick={() => {
                dispatch(toggleDarkMode(!darkMode));
              }}
              icon={
                darkMode ? (
                  <Brightness2Icon sx={{ fontSize: 32 }} />
                ) : (
                  <Brightness2OutlinedIcon sx={{ fontSize: 32 }} />
                )
              }
            />
          </Box>

          <ListItem>
            <IconButton
              aria-label="view notifications"
              ref={notificationIconsRef}
              color="primary"
              className={classes.notificationsIcon}
              onClick={toggleNotifications}
              size="large">
              <StyledBadge
                badgeContent={notificationsBadgeCount}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                color={"secondary"}>
                <Notifications style={{ fontSize: 32 }} />
              </StyledBadge>
            </IconButton>
            <NotificationsMenu
              notificationIconsRef={notificationIconsRef}
              notifications={firebaseNotifications}
              isNotificationsOpen={isNotificationsOpen}
              handleClose={handleClose}
            />
          </ListItem>

          <ListItem>
            <IconButton
              aria-label={`Alethea account: ${currentUser.displayName}`}
              color="primary"
              className={classes.notificationsIcon}
              ref={avatarElRef}
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              size="large">
              <CustomAvatar
                id={currentUser.uid}
                name={currentUser.displayName}
                src={avatar ? avatar : ""}>
                {isImpersonation && (
                  <SupervisedUserCircleIcon fontSize="inherit" />
                )}
              </CustomAvatar>
            </IconButton>
          </ListItem>
          {renderMenu()}
        </List>
      </AppBar>
    </>
  );
}
