// Dependancies
import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { acceptTermsOfUse } from "../../redux/userSlice";

// Redux dependancies
import { selectAcceptedTermsOfUse } from "../../redux/firestoreSelectors";

// Material UI
import {
  Checkbox,
  FormControlLabel,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  Link,
  Typography,
  DialogActions
} from "@mui/material";

//Styles
const useStyles = makeStyles((theme) => ({
  dialogBtn: {
    backgroundColor: "#168fee",
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      background: "#1172be"
    }
  },
  dialogTitle: {
    textAlign: "center"
  }
}));

export default function TermsOfUseDialog() {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  // Global state
  const acceptedTermsOfUse = useSelector((state) =>
    selectAcceptedTermsOfUse(state)
  );

  // Ephemeral State
  const [agree, setAgree] = useState(false);
  const [termsError, setTermsError] = useState("");

  // Render
  return (
    <Dialog open={!acceptedTermsOfUse} onClose={() => {}}>
      <DialogTitle className={classes.dialogTitle}>
        Terms and Conditions
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="accept-terms-checkbox"
              checked={agree}
              onChange={() => {
                setAgree(!agree);
              }}
              color="primary"
            />
          }
          error={termsError}
          label={
            <Typography>
              I accept the{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="/terms.pdf"
                className={classes.termsLink}
                variant="body1"
                underline="hover">
                terms and conditions
              </Link>{" "}
              and{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="/privacy.pdf"
                className={classes.termsLink}
                variant="body1"
                underline="hover">
                privacy policy
              </Link>
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          aria-label={"I accept"}
          data-test-id="accept-terms-confirm-btn"
          className={classes.dialogBtn}
          onClick={() => {
            if (agree) {
              dispatch(acceptTermsOfUse());
            } else {
              setTermsError(
                "you must accept the terms to continue using Alethea"
              );
            }
          }}>
          <FormattedMessage id="terms.accept" defaultMessage="Accept" />
        </Button>
        <Button
          onClick={() => {
            navigate("/logout");
          }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
